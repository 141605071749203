<template>
  <div class="select-car">
    <el-autocomplete @dblclick.native="autoDbClick" @blur="autoFocusOut" v-model.trim="deviceName" :fetch-suggestions="fetchVehicleList" size="small" popper-class="v-popper-dehicle" clearable
      :placeholder="$t('commonListVehicle.searchVehicle')"  ref="autoSearch" :trigger-on-focus="false" :debounce="600">
      <template slot-scope="{item}">
        <p class="vehicle" @click.stop> 
        <template v-if="item.items[0].TypeID===1"><i-icon name="iconuser-person-copy" style="flex: 0 0 16px;"></i-icon></template>
        <template v-if="item.items[0].TypeID===2"><i-icon name="iconuser-airte-copy" style="flex: 0 0 16px;"></i-icon></template>
        <template v-if="item.items[0].TypeID===0"><i-icon name="iconuser-car-copy" style="flex: 0 0 16px;"></i-icon></template></p>
        <p class="device" :title="item.subgroup" @click="handlerLine(item)">
          <i-icon name="icon-crosshairs-gps"></i-icon> {{item.subgroup}}
          <!-- <span class="device__tip" v-if="!item.RcvTime">{{$t('commonListVehicle.unused')}}</span> -->
        </p>
        <!-- <p class="empty-text" v-if="!item.SIM2">无绑定设备</p> -->
      </template>
    </el-autocomplete>
  </div>
</template>

<script>
// import { QueryTreeVehicleList } from '@/api/tree'
import { DeviceSearch } from '@/api/common'

export default {
  props:{
     searchName: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      deviceName: ''
    }
  },
  watch:{
    deviceName(val){
      this.$emit('bindKey', val)
    },
    searchName(val){
      this.deviceName = val
    }
  },
  methods: {
    autoDbClick() {
      // console.log('dbclick')
      this.$refs.autoSearch.activated = true
      this.$refs.autoSearch.suggestionDisabled = false
      this.$refs.autoSearch.debouncedGetData(this.$refs.autoSearch.value)
    },
    autoFocusOut() {
      if (this.$refs.autoSearch.value) {
        this.$refs.autoSearch.suggestionDisabled = true
      }
    },
    fetchVehicleList(queryKey, cb) {
      const promise = new Promise((resolve) => {
        DeviceSearch(queryKey, null).then(ret => {
          let list = ret.data.outList
          let temp = []
          list.map((element)=>{
            temp = temp.concat(element.items)
          })
          cb && cb(temp.slice().map(k => Object.assign(k, {value:k.subgroup})))
          resolve(temp)
        })
      })
      return promise
    },
    handlerLine(item) {
      this.deviceName = item.subgroup
      this.$emit('select', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.select-car{
  width:100%;
}
.el-autocomplete {
  position: relative;
  width: 100%;
  & ::v-deep .el-input__inner{
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    font-size: 12px;
    background-color: #182B3B;
    border-color: #182B3B;
    color: #fff;
  }
  & ::v-deep .el-input--prefix input {
    padding-left: 32px;
    // border-top-left-radius: 16px;
    // border-bottom-left-radius: 16px;
    // box-shadow: 0 2px 6px 0 rgba(114, 124, 245, 0.5);
  }
  & ::v-deep .el-input-group__append {
    padding: 0;
    border-radius: 12px;
    position: absolute;
    top: 1px;
    background-color: #F5F7FA;
    z-index: 1;
    right: 1px;
    width: 28px;
    height: 26px;
    border-width: 0;
    border-left: 1px solid #dcdfe6;
    i {
      padding: 6px 8px;
      cursor: pointer;
    }
  }
}
</style>